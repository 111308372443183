import React from 'react';

const Footer = () => (
	<footer className="footer">
		<div className="wrap">
			<ul>
				<li><a href="https://twitter.com/blienart" target="_blank" rel="noopener noreferrer">Twitter</a></li>
				<li><a href="https://github.com/Bnjis" target="_blank" rel="noopener noreferrer">GitHub</a></li>
			</ul>
		</div>
	</footer>
)


export default Footer;
