import React from 'react';
import { Link } from 'gatsby';

const Nav = () => (
	<nav className="nav-menu">
		<ul className="nav-menu-list">
			<li className="nav-menu-item">
				<Link 
				activeClassName="current"
				to="/about">
					About
				</Link>
			</li>
			<li className="nav-menu-item">
				<Link 
				activeClassName="current"
				to="/blog">
					Blog
				</Link>
			</li>
			<li className="nav-menu-item">
				<Link 
				activeClassName="current"
				to="/contact">
					Contact
				</Link>
			</li>
		</ul>
	</nav>
);

export default Nav;