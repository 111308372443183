import React from 'react';
import PropTypes from "prop-types";

import Header from './Header';
import Footer from './Footer';
import SEO from './seo';

import '../styles/main.scss';

const Layout = ({children, title}) => (
	<>
		<Header />
		<main>
			<div className="wrap">
				{children}
			</div>	
		</main>
		<Footer />
		<SEO title={title} />
	</>
)

Layout.propTypes = {
	children: PropTypes.node.isRequired
}

export default Layout;
