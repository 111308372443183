import React from 'react';
// import PropTypes from "prop-types";
import { Link } from 'gatsby';

import Nav from './Nav';

const Header = () => (
	<header className="header">
		<div className="wrap">
			<Link 
				className="logo"
				to="/">blienart <span>Blog</span>
			</Link>
			<Nav/>
		</div>
	</header>
)


export default Header;
